import { useEffect, useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  ButtonGroup,
  Input,
  FormControl,
  FormLabel,
  useToast,
  Checkbox, Box, Select,
} from '@chakra-ui/react';
import styles from "./user_modal.module.scss";
import { useAuth } from "./../../contexts/auth_context";
import { modalAction } from "@/utils/modal_action"

const UserModal = ({
  isOpen,
  onClose,
  users,
  user,
  userAction}) => {

  const employeeType = 'employee';
  const subcontractorType = 'subcontractor';

  const isEdit = user ? true : false;
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [payrollId, setPayrollId] = useState('');
  const authContext = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const [employmentType, setEmploymentType] = useState(employeeType);
  const [supervisorId, setSupervisorId] = useState(null);
  const [isSupervisor , setIsSupervisor] = useState(false);
  const [sendWelcomeMessage, setSendWelcomeMessage] = useState<boolean>(false);

  useEffect(() => {
    setName(user?.name || '');
    setPhone(user?.phoneNumber || '');
    setPayrollId(user?.payrollId || '');
    setSupervisorId(user?.supervisorId || null);
    setIsSupervisor(user?.isSupervisor || false);
    setEmploymentType(user?.employmentType || employeeType);
  }, [isOpen]);

  const handleSubmit = () => {
    const userParams = {
      name,
      phone,
      employmentType,
      payrollId,
      isSupervisor,
    }

    if (employmentType === subcontractorType) {
      userParams['supervisorId'] = supervisorId;
    }

    if (userAction == modalAction.CREATE) {
      userParams['sendWelcomeMessage'] = sendWelcomeMessage;
    }

    setIsLoading(true);
    authContext.authenticatedFetch(userAction == modalAction.UPDATE ? `${import.meta.env.VITE_API_SERVER}/users/${user.id}` : `${import.meta.env.VITE_API_SERVER}/users`, {
      method: userAction == modalAction.UPDATE ? "PUT" : "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userParams),
    }).then(( response: Response ) => {
      return response.json();
    }).then(() => {
      onClose(false, true);
    }).catch((error) => {
      toast({
        description: error.message,
        position: "bottom",
        status: "error",
        variant: "left-accent",
        duration: 5000,
        isClosable: true,
      });
    }).finally(() => {
      setIsLoading(false);
    })
  }

  const handleDelete = () :void => {
    setIsLoading(true);
    authContext.authenticatedFetch(`${import.meta.env.VITE_API_SERVER}/users/${user.id}`,
      {
        method: "delete"
    }).then(( response: Response ) => {
      return response.json();
    }).then(() => {
      onClose(true);
    }).catch((error) => {
      toast({
        description: error.message,
        position: "bottom",
        status: "error",
        variant: "left-accent",
        duration: 5000,
        isClosable: true,
      });
    }).finally(() => {
      setIsLoading(false);
    })
  }

  const renderDeleteButton = () => {
    return (
      <Button
        flex={1}
        variant='solid'
        bg='secondary.red'
        colorScheme='red'
        onClick={handleDelete}>
        Delete
      </Button>
    )
  }

  const renderEditModalBody = () => {
    return (
        <ModalContent>
          <ModalHeader>{userAction == modalAction.UPDATE ? "Edit User" : "Create User"}</ModalHeader>
          <ModalCloseButton/>
          <ModalBody>
            <FormControl className={styles.fieldContainer}>
              <FormLabel className={styles.fieldLabel}>Name</FormLabel>
              <Input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Enter name"
              />
            </FormControl>
            <FormControl className={styles.fieldContainer}>
              <FormLabel className={styles.fieldLabel}>Phone Number</FormLabel>
              <Input
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  placeholder="Enter phone number"
              />
            </FormControl>
            <FormControl className={styles.fieldContainer}>
              <FormLabel className={styles.fieldLabel}>Payroll Provider ID</FormLabel>
              <Input
                  value={payrollId}
                  onChange={(e) => setPayrollId(e.target.value)}
                  placeholder="Enter Payroll Provider ID"
              />
            </FormControl>
            <FormControl className={styles.fieldContainer}>
              <FormLabel className={styles.fieldLabel}>Employment Type</FormLabel>
              <Select value={employmentType} onChange={(e) => setEmploymentType(e.target.value)}>
                <option value={employeeType}>Employee</option>
                <option value={subcontractorType}>Subcontractor</option>
              </Select>
            </FormControl>
            {employmentType === subcontractorType && (
                <FormControl className={styles.fieldContainer}>
                  <FormLabel className={styles.fieldLabel}>Which subcontractor will manage this cleaner?</FormLabel>
                  <Select value={supervisorId} onChange={(e) => setSupervisorId(Number(e.target.value))}>
                    <option key='default' value=''>None</option>
                    {users.map((supervisor) => (
                        (!user || (user.id !== supervisor.id)) && (
                            <option key={supervisor.id} value={supervisor.id}>
                              {supervisor.name}
                            </option>
                        )
                    ))}
                  </Select>
                </FormControl>
            )}
            <FormControl className={styles.checkboxContainer}>
              <Checkbox
                  isChecked={isSupervisor}
                  onChange={(e) => setIsSupervisor(e.target.checked)}
              />
              <FormLabel className={styles.checkboxLabel}>Supervisor</FormLabel>
            </FormControl>
            {userAction == modalAction.CREATE && (<FormControl className={styles.checkboxContainer}>
              <Checkbox
                  isChecked={sendWelcomeMessage}
                  onChange={(e) => setSendWelcomeMessage(e.target.checked)}
              />
              <FormLabel className={styles.checkboxLabel}>Send text message with BrightGo app download link</FormLabel>
            </FormControl>)}
          </ModalBody>
          <ModalFooter>
            <ButtonGroup width="100%" spacing="6">
              <Button flex={1} colorScheme="gray" isLoading={isLoading} onClick={() => onClose()}>
                Cancel
              </Button>
              <Button flex={1} variant="solid" isLoading={isLoading} onClick={handleSubmit}>
                {userAction == modalAction.UPDATE ? "Update" : "Create"}
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
    );
  };

  const renderDeleteModalBody = () => {
    return (
        <ModalContent>
          <ModalHeader>Confirmation Required</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box style={{ textAlign: "center" }}>
              Are you sure you want to delete user {name}?
            </Box>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup width="100%" spacing='6'>
              <Button flex={1} colorScheme='gray'
                      isLoading={isLoading}
                      onClick={() => onClose()}>
                Cancel
              </Button>
              {renderDeleteButton()}
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
    )
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"lg"} isCentered>
      <ModalOverlay />
      {userAction == modalAction.DELETE ? renderDeleteModalBody() : renderEditModalBody() }
    </Modal>
  );
};

export default UserModal;
