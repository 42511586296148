import {Box, Flex, Icon, SimpleGrid, Td, Text, useTheme} from "@chakra-ui/react";
import {FaCircleCheck} from "react-icons/fa6";
import {formatPhoneNumber} from "@/utils/formatting";

const UserInfo = ({ user }) => {
    const theme = useTheme();
    return (
        <Box
            borderRadius="3xl"
            backgroundColor="white"
            border={`1px solid ${theme.colors.gray[200]}`}
            padding={"1.75em"}
            width={"100%"}
        >
            <SimpleGrid columns={{base: 1, sm: 3, md: 4, lg: 4}} spacing={"2em"}>
                <Box>
                    <Text
                        color={theme.colors.secondary.deepGray}
                        marginBottom={"0.5em"}
                        minWidth={"20%"}>
                        Name
                    </Text>
                    {user.name || '-'}
                </Box>
                <Box>
                    <Text
                        color={theme.colors.secondary.deepGray}
                        marginBottom={"0.5em"}
                        minWidth={"20%"}>
                        Username
                    </Text>
                    {user.username || '-'}
                </Box>
                <Box>
                    <Text
                        color={theme.colors.secondary.deepGray}
                        marginBottom={"0.5em"}
                        minWidth={"20%"}>
                        Phone Number
                    </Text>
                    {formatPhoneNumber(user.phoneNumber) || '-'}
                </Box>
                <Box>
                    <Text
                        color={theme.colors.secondary.deepGray}
                        marginBottom={"0.5em"}
                        minWidth={"20%"}>
                        Payroll Provider ID
                    </Text>
                    {user.payrollId || '-'}
                </Box>
                <Box>
                    <Text
                        color={theme.colors.secondary.deepGray}
                        marginBottom={"0.5em"}
                        minWidth={"20%"}>
                        Supervisor
                    </Text>
                    {(user.isSupervisor &&
                        <Icon as={FaCircleCheck} color="primary.500"/>) || '-'}
                </Box>
                <Box>
                    <Text
                        color={theme.colors.secondary.deepGray}
                        marginBottom={"0.5em"}
                        minWidth={"20%"}>
                        App Installed
                    </Text>
                    {(user.lastClientRequestAt &&
                        <Icon as={FaCircleCheck} color="primary.500"/>) || '-'}
                </Box>
            </SimpleGrid>
        </Box>
    )

}

export default UserInfo;
