import { useNavigate, useParams } from "react-router-dom";
import useSWR, {mutate} from "swr";

import { Box, Button, Flex, Heading, Icon, Text } from "@chakra-ui/react";
import { BsArrowLeftShort } from "react-icons/bs";

import { useAuth } from "@/contexts/auth_context";

import { ErrorPage } from "@/components/ErrorPage";
import { FullScreenSpinner } from "@/components/FullScreenSpinner";

import styles from "./styles.module.scss";
import {UserInfo} from "@/components/UserInfo";
import {modalAction} from "@/utils/modal_action";
import {UserModal} from "@/components/UserModal";
import {useState} from "react";
import {sortUsers} from "@/utils/user_utils";

const UserPage = () => {
    const { user_id } = useParams();
    const userId = parseInt(user_id || "");
    const authContext = useAuth();
    const navigate = useNavigate()
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [userAction, setUserAction] = useState<modalAction | null>(null);


    const getUserUrl = (userId: number) => {
        return `${import.meta.env.VITE_API_SERVER}/users/${userId}`
    }

    const getUsersUrl = () => {
        return `${import.meta.env.VITE_API_SERVER}/users`
    }

    const { data, error, isLoading } = useSWR(
        getUserUrl(userId),
        url => {
            return authContext
                .authenticatedFetch(url)
                .then(response => { return response.json() })
        }
    );

    const { data: usersData, error: usersError, isLoading: usersLoading } = useSWR(
        getUsersUrl(),
        url => {
            return authContext
                .authenticatedFetch(url)
                .then(response => { return response.json() })
        }
    );

    const user = data?.user;
    const users = usersData?.users;
    const sortedUsers = users ? sortUsers(users) : [];

    if (!user || !users) {
        return <ErrorPage errorMessage="Failed to fetch user data" />;
    }

    if (isLoading || usersLoading) {
        return <FullScreenSpinner />;
    }

    // handle network errors
    if (error || usersError) {
        return <ErrorPage error={error} />;
    }

    // handle API specific errors
    if (data.error || usersData.error) {
        return <ErrorPage errorMessage={data.error || usersData.error} />;
    }

    return (
        <Flex
            flexDirection={"column"}
            height={"100%"}
            padding={"2.25em 1.75em"}
        >
            <Flex
                flexDirection={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
            >
                <Box>
                    <Flex
                        flexDirection={"row"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                    >
                        <Icon
                            className={styles.icon}
                            onClick={() => navigate(`/users/`)}
                            as={BsArrowLeftShort}
                            boxSize={10}
                            marginRight={"1em"}
                            color={"primary.900"}
                            cursor={"pointer"}
                        />
                        <Heading
                            as={"h1"}
                            size={"lg"}
                            margin={0}
                        >
                            <Text>{user.name}</Text>
                        </Heading>
                    </Flex>
                </Box>
                <Box>
                    <Flex>
                        <Box
                        marginRight={"1em"}>
                            <Button
                                isDisabled={isLoading}
                                onClick={() => {
                                    setUserAction(modalAction.UPDATE);
                                    setIsEditModalOpen(true);
                                }}>
                                Edit User
                            </Button>
                        </Box>
                        <Box>
                            <Button
                                isDisabled={isLoading}
                                variant='solid'
                                colorScheme='red'
                                onClick={() => {
                                    setUserAction(modalAction.DELETE);
                                    setIsEditModalOpen(true);
                                }}>
                                Delete User
                            </Button>
                        </Box>
                    </Flex>
                </Box>
            </Flex>
            <Flex
                marginTop={"1.5em"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                height={"100%"}
                position={"relative"}
            >
                <Flex
                    flexDirection={"column"}
                    flex={"1"}
                >
                    <Box
                        height={"100%"}
                        paddingBottom={"3.5em"}
                    >
                        <Heading
                            size={"md"}
                            fontWeight={"medium"}
                            marginBottom={"1em"}
                        >
                            <Text fontWeight={"medium"}>Personal Information</Text>
                        </Heading>
                        <UserInfo user={user} />
                    </Box>
                </Flex>
            </Flex>
            <UserModal
                isOpen={isEditModalOpen}
                userAction={userAction}
                user={user}
                users={sortedUsers}
                onClose={(deleted = false, edited = false) => {
                    setIsEditModalOpen(false)
                    setUserAction(null);

                    if (deleted) {
                        navigate("/users/");
                    }
                    if (edited) {
                        mutate(getUserUrl(userId));
                        mutate(getUsersUrl());
                    }
                }}
            />
        </Flex >
    )
}

export default UserPage;
