import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Text,
  Image,
  useTheme,
} from '@chakra-ui/react'

import { PasswordField } from '../../components/PasswordField';
import { ChangeEvent, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/auth_context";
const SignIn = () => {
  const navigate = useNavigate();
  const authContext = useAuth();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => setPassword(event.target.value);
  const handleUsernameChange = (event: ChangeEvent<HTMLInputElement>) => setUsername(event.target.value);
  const [ isPasswordFormShown, setIsPasswordFormShown ] = useState(false);
  const [ passwordInstruction, setPasswordInstruction ] = useState("");
  const [ loading, setLoading ] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    if (authContext.state.userToken) {
      return navigate("/");
    }
  }, [authContext.state.userToken]);

  const switchToPasswordForm = (showPasswordForm: boolean) => {
    setIsPasswordFormShown(showPasswordForm);
    setLoading(false)
    setErrorMessage("")
    setPassword("")
    setPasswordInstruction("")
  };

  const renderUsernameForm = () => {
    return (
      <Stack spacing="6">
        <Stack spacing="5">
          <FormControl>
            <FormLabel htmlFor="username">Username</FormLabel>
            <Input id="username" type="username" onChange={handleUsernameChange} value={username}/>
          </FormControl>
        </Stack>
        <Stack spacing="6">
          <Button
            variant="solid"
            isLoading={loading}
            isDisabled={!username || username.trim() === ""}
            onClick={async () =>
            {
              setErrorMessage("");
              setLoading(true);
              const sendCodeResult = await authContext.sendCode(username);
              if(sendCodeResult.isError) {
                setErrorMessage(sendCodeResult.message);
              } else {
                switchToPasswordForm(true);
                setPasswordInstruction(sendCodeResult.message);
              }
              setLoading(false);
          }}>Continue</Button>
          <Text
            fontSize="sm"
            color={theme.colors.secondary.darkGray}
            textAlign="right"
            cursor="pointer"
            display={!username || username.trim() === "" ? "none" : "block"}
            onClick={() => switchToPasswordForm(true)}
          >
            Already have a code?
          </Text>
        </Stack>
      </Stack>
    )
  };

  const renderPasswordForm = () => {
    return (
      <Stack spacing="6">
        <Stack spacing="5">
          <PasswordField onChange={handlePasswordChange} value={password} message={passwordInstruction}/>
        </Stack>
        <Stack spacing="4">
          <Button
            variant="solid"
            isLoading={loading}
            loadingText='Logging in...'
            isDisabled={!password || password.trim() === ""}
            onClick={async () =>
            {
              setErrorMessage("");
              setLoading(true);
              const newError =await authContext.signIn({ username, password }, authContext.dispatch);
              setErrorMessage(newError);
              setLoading(false);
          }}>Log in</Button>
          <Button variant="outline" onClick={() => {
            switchToPasswordForm(false);
          }}>Go back</Button>
        </Stack>
      </Stack>
    )
  };


  return (
    <Container maxW="100%" py={{ base: '12', md: '24' }} px={{ base: '0', sm: '8' }} h='calc(100vh)' bg={"gray.50"} centerContent>
      <Stack spacing="8" w={'350px'}>
        <Stack spacing="6">
          <Stack spacing={{ base: '2', md: '3' }} alignItems="center">
            <Image
              width="250px"
              src={import.meta.env.BASE_URL + "image_65-removebg-preview.png"}
            />
          </Stack>
        </Stack>

        <Box
          maxW={"lg"}
          pt={{ base: '0', sm: '8' }}
          pb={{ base: '0', sm: '4' }}
          px={{ base: '4', sm: '10' }}
          boxShadow={{ base: 'none', sm: 'md' }}
          borderRadius={{ base: 'none', sm: 'xl' }}
          bg={"white"}
        >
          {isPasswordFormShown ? renderPasswordForm() : renderUsernameForm()}
          <Stack spacing="6" mt={2}>
            <Text fontSize='sm' color={'#C53030'}>{errorMessage || '\u00A0'}</Text>
          </Stack>
        </Box>
      </Stack>
    </Container>
  );
};

export default SignIn;
