import {
  Flex,
  Tag,
  TagLabel,
  TagCloseButton,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
  useDisclosure
} from '@chakra-ui/react';
import { CalendarEventLabel } from '../../types/calendarEvent';
import CalendarEventLabelModal from './calendar_event_label_modal';
import { useState } from 'react';
import { FiSettings, FiPlus } from 'react-icons/fi';

interface CalendarEventLabelsProps {
  labels: number[];
  updateLabels: (labels: number[]) => void;
  labelsMap: Record<number, CalendarEventLabel>;
}

const CalendarEventLabels: React.FC<CalendarEventLabelsProps> = ({
  labels,
  updateLabels,
  labelsMap,
}) => {
  // has value -> editing existing label
  // null -> creating new label
  // undefined -> no action
  const [ selectedLabel, setSelectedLabel ] = useState<CalendarEventLabel | null | undefined>(undefined);
  const { isOpen : isMenuOpen, onOpen : onMenuOpen, onClose : onMenuClose } = useDisclosure();
  const validLabels = labels.filter((labelId) => labelsMap[labelId]);

  const renderEmptyState = (): JSX.Element => (
    <Menu isOpen={isMenuOpen} onClose={onMenuClose} onOpen={onMenuOpen}>
      <MenuButton
        as={Button}
        variant="ghost"
        size="sm"
      >
        + Add label
      </MenuButton>
      <MenuList>
        {Object.entries(labelsMap).map(([id, label]) => (
          <MenuItem
            key={id}
            onClick={() => {
              updateLabels([Number(id)]);
              onMenuClose();
            }}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Tag size="lg" borderRadius="full" bgColor={label.color} color="white">
              <TagLabel>{label.name}</TagLabel>
            </Tag>
            <Icon
              as={FiSettings}
              ml={2}
              onClick={(e) => {
                e.stopPropagation();
                setSelectedLabel(label);
                onMenuClose();
              }}
              cursor="pointer"
              _hover={{ color: 'blue.500' }}
            />
          </MenuItem>
        ))}
        <MenuItem
          onClick={() => {
            setSelectedLabel(null);
            onMenuClose();
          }}
        >
          <Icon as={FiPlus} mx={2} width={3} height={3}/>
          Create new label
        </MenuItem>
      </MenuList>
    </Menu>
  );

  const renderLabels = (): JSX.Element => (
    <Flex gap={2}>
      {validLabels.map((labelId) => (
        <Tag
          key={labelId}
          size="lg"
          borderRadius="full"
          variant="solid"
          bgColor={labelsMap[labelId].color}
          px={4}
        >
          <TagLabel>{labelsMap[labelId].name}</TagLabel>
          <TagCloseButton onClick={() => updateLabels([])} />
        </Tag>
      ))}
    </Flex>
  );

  return (
    <Flex gap={2}>
      {validLabels.length === 0 ? renderEmptyState() : renderLabels()}
      <CalendarEventLabelModal
        isOpen={selectedLabel !== undefined}
        onClose={() => {
          setSelectedLabel(undefined);
        }}
        label={selectedLabel}
      />
    </Flex>
  );
};

export default CalendarEventLabels;
