import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { CookiesProvider } from 'react-cookie';
import * as Sentry from "@sentry/react";

import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route,
  useNavigate,
  Navigate,
  Outlet,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";

import App from './App'
import {RouterErrorPage} from "./views/RouterErrorPage";
import {Home} from "./views/Home";
import {Timesheets} from "./views/Timesheets";
import { ShiftPage } from './views/ShiftPage';
import {SignIn} from "./views/SignIn";
import {AppStoreRedirect} from "./views/AppStoreRedirect";
import {Sidebar} from "./components/Sidebar";
import {OperationsDashboard} from "./views/OperationsDashboard";
import {TimezoneProvider} from "@contexts/timezone_context";
import {AuthProvider, useAuth} from "@contexts/auth_context";
import {ClockInDashboard} from "@/views/ClockInDashboard";
import {EmployeeReport} from "@/views/EmployeeReport";
import { UserDashboard } from './views/UserDashboard';
import {OldSchedulesPage} from "@/views/OldSchedulesPage";
import {SchedulesPage} from "@/views/SchedulesPage";
import {Messaging} from "@/views/Messaging";
import { InspectionDashboard } from '@/views/InspectionDashboard';
import { InspectionPage } from '@/views/InspectionPage';
import { EmployeePage } from '@/views/EmployeePage';
import { TaskHistoryPage } from './views/TaskHistoryPage';
import IssueDashboard from './views/IssueDashboard/issue_dashboard';
import { IssueDetailPage } from './views/IssueDetailPage';
import { BuildingDashboard } from './views/BuildingDashboard';
import { BuildingPage } from './views/BuildingPage';
import { Reports } from './views/Reports';
import { RealTimeLaborDashboard } from './views/RealTimeLaborDashboard';
import { TimesheetDashboard } from './views/TimesheetDashboard';
import { TimesheetCard } from './views/TimesheetCard';
import { BuildingReport } from './views/BuildingReport';
import {UserPage} from "@/views/UserPage";


Sentry.init({
  dsn: "https://977bfaa5814925b0c2431bf775572a90@o4504906433626112.ingest.us.sentry.io/4506514409717760",
  integrations: [
    new Sentry.BrowserTracing({
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new Sentry.Replay()
  ],

  // High trace sampling can cause performance issues
  // https://docs.sentry.io/platforms/javascript/guides/react/configuration/sampling/#sampling-transaction-events
  tracesSampleRate: 0.1,
  enabled: process.env.NODE_ENV !== 'development',

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^https:\/\/app\.brightgo\.com/],

  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});


const ProtectedRoute = ({
                          redirectPath = '/sign_in',
                          children,
                        }) => {

  const authContext = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    if (!authContext.state.userToken) {
      return navigate(redirectPath);
    }
  }, [authContext.state.isSignout]);

  if (!authContext.state.userToken) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter(
  createRoutesFromElements(
    <>
    <Route
      path="/"
      element={<App />}
      errorElement={<RouterErrorPage />}
    >
      <Route path={"update"} element={<AppStoreRedirect />}/>
      <Route path={"sign_in"} element={<SignIn />}/>
      <Route element={<Sidebar />}>
        <Route index element={
          <ProtectedRoute>
            <Home />
          </ProtectedRoute>
        } />
        <Route
          path={"v1schedules/:date?"}
          element={
            <ProtectedRoute>
              <OldSchedulesPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={"schedules/:date?"}
          element={
            <ProtectedRoute>
              <SchedulesPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={"cleaner-report"}
          element={
            <ProtectedRoute>
              <EmployeeReport />
            </ProtectedRoute>
          }
        />
        <Route
          path={"users"}
          element={
            <ProtectedRoute>
              <UserDashboard />
            </ProtectedRoute>
          }
        />
      <Route
          path={"user/:user_id"}
          element={
              <ProtectedRoute>
                  <UserPage />
              </ProtectedRoute>
          }
      />
        <Route
          path={"timesheets"}
          element={
            <ProtectedRoute>
              <TimesheetDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path={"timesheet_card"}
          element={
            <ProtectedRoute>
              <TimesheetCard />
            </ProtectedRoute>
          }
        />
        <Route
          path={"operations_dashboard"}
          element={
            <ProtectedRoute>
              <OperationsDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path={"clock_in_dashboard/:date?"}
          element={
            <ProtectedRoute>
              <ClockInDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path={"shift/:shift_id"}
          element={
            <ProtectedRoute>
              <ShiftPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={"messaging"}
          element={
            <ProtectedRoute>
              <Messaging />
            </ProtectedRoute>
          }
        />
        <Route
          path={"inspection"}
          element={
            <ProtectedRoute>
              <InspectionDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path={"inspection/:inspection_id"}
          element={
            <ProtectedRoute>
              <InspectionPage />
            </ProtectedRoute>
          }
        />
        <Route
            path={"employee/:employee_id"}
            element={
              <ProtectedRoute>
                <EmployeePage />
              </ProtectedRoute>
            }
        />
        <Route
          path={"task_history"}
          element={
            <ProtectedRoute>
              <TaskHistoryPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="issues"
          element={
            <ProtectedRoute>
              <IssueDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="issues/:issue_id"
          element={
            <ProtectedRoute>
              <IssueDetailPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={"buildings"}
          element={
            <ProtectedRoute>
              <BuildingDashboard />
            </ProtectedRoute>
          }
         />
        <Route
          path={"buildings/:building_id"}
          element={
            <ProtectedRoute>
              <BuildingPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={"reports"}
          element={
            <ProtectedRoute>
              <Reports />
            </ProtectedRoute>
          }
         />
        <Route
          path={"hours"}
          element={
            <ProtectedRoute>
              <RealTimeLaborDashboard />
            </ProtectedRoute>
          }
         />
        <Route
          path={"buildings_report"}
          element={
            <ProtectedRoute>
              <BuildingReport />
            </ProtectedRoute>
          }
        />
      </Route>
    </Route>

    </>
  )
);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <CookiesProvider>
      <AuthProvider>
        <TimezoneProvider>
          <RouterProvider router={router} />
        </TimezoneProvider>
      </AuthProvider>
    </CookiesProvider>
  </React.StrictMode>,
);
