import { useAuth } from "@contexts/auth_context";
import {
  Heading,
  Text,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useTheme,
  Button,
  Box,
  Icon,
  PopoverTrigger,
  Portal,
  PopoverContent,
  PopoverBody,
  List,
  ListItem,
  Popover,
  ListIcon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList, ButtonGroup, PopoverArrow
} from "@chakra-ui/react";
import { FullScreenSpinner } from "@/components/FullScreenSpinner";
import {ErrorPage} from "@/components/ErrorPage";
import useSWR, { mutate} from "swr";
import {useState} from "react";
import styles from "./styles.module.scss";
import { UserModal } from "@/components/UserModal";
import { formatPhoneNumber } from "@/utils/formatting";
import { FaCircleCheck } from "react-icons/fa6";
import {useNavigate} from "react-router-dom";
import {BsPencilFill, BsThreeDotsVertical, BsTrashFill} from "react-icons/bs";
import {modalAction} from "@/utils/modal_action";
import {sortUsers} from "@/utils/user_utils";

const USERS_INDEX_URL = `${import.meta.env.VITE_API_SERVER}/users`;

const UserDashboard: React.FC = () => {
  const navigate = useNavigate();
  const authContext = useAuth();
  const theme = useTheme();
  const [ userToBeEdited, setUserToBeEdited ] = useState(null);
  const [ isEditModalOpen, setIsEditModalOpen ] = useState<boolean>(false);
  const [ userAction, setUserAction ] = useState<modalAction | null>(null);
  const [ activeRow, setActiveRow ] = useState<number | null>(null)

  const { data, error, isLoading } = useSWR(
    USERS_INDEX_URL,
    url => {
      return authContext.authenticatedFetch(url, {
        method: "get",
      })
      .then(response => response.json())
    }
  );

  if (isLoading) {
    return <FullScreenSpinner />;
  }

  // handle network errors
  if (error) {
    return <ErrorPage error={error}/>;
  }

  // handle API specific errors
  if (data.error) {
    return <ErrorPage errorMessage={data.error}/>;
  }

  const onRowClick = (userId, userAction) => {
    setUserToBeEdited(userId);
    setUserAction(userAction);
    setIsEditModalOpen(true);
  };

  const { users } = data;

  const allUsers = sortUsers(users);

  return (
    <Flex
      flexDirection={"column"}
      height="100%"
    >
      <Flex
        flexDirection={"row"}
        pt={6}
        mx={6}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Heading
          as="h1"
          size="lg"
          marginBottom={0}
        >
          <Text>Users</Text>
        </Heading>
        <Button
            isDisabled={isLoading}
            onClick={() => {
              setUserAction(modalAction.CREATE);
              setIsEditModalOpen(true);
          }}>
            Create user
        </Button>
      </Flex>
      <Box
        borderRadius="3xl"
        flex={1}
        overflowY="hidden"
        backgroundColor="white"
        border={`1px solid ${theme.colors.gray[200]}`}
        display="flex"
        flexDirection="column"
        margin={6}
        height="100%"
      >
        <Flex
          my={6}
          flex={1}
          flexDirection="column"
          overflowY="hidden"
        >
          <Flex className={styles.tableHeaderContainer}>
            <Table variant="simple" colorScheme="gray">
              <Thead position="sticky" top={0} bg="white">
                <Tr>
                  <Th width="25%" textColor={"gray.500"} textTransform={"none"} fontWeight={"normal"} fontSize={12} pl={6} >Name</Th>
                  <Th width="25%" textColor={"gray.500"} textTransform={"none"} fontWeight={"normal"} fontSize={12} pl={0} >Username</Th>
                  <Th width="25%" textColor={"gray.500"} textTransform={"none"} fontWeight={"normal"} fontSize={12} pl={0} >Phone Number</Th>
                  <Th width="10%" textColor={"gray.500"} textTransform={"none"} fontWeight={"normal"} fontSize={12} pl={0} style={{ textAlign: "center" }} >Supervisor</Th>
                  <Th width="10%" textColor={"gray.500"} textTransform={"none"} fontWeight={"normal"} fontSize={12} pl={0} style={{ textAlign: "center" }} >App Installed</Th>
                  <Th width="5%" textColor={"gray.500"} textTransform={"none"} fontWeight={"normal"} fontSize={12} pl={0} style={{ textAlign: "center" }} ></Th>
                </Tr>
              </Thead>
            </Table>
          </Flex>
          <Flex
            overflowY="scroll"
          >
            <Table variant="simple" colorScheme="gray">
              <Tbody>
                {allUsers.map((user) => (
                  <Tr width="100%"
                      key={user.id}
                      _hover={{bg: 'gray.50', cursor: "pointer"}}
                      onClick={()=> { navigate(`/user/${user.id}`)}}
                  >
                    <Td width="25%" pl={6}>{user.name}</Td>
                    <Td width="25%" pl={0}>{user.username}</Td>
                    <Td width="25%" pl={0}>{formatPhoneNumber(user.phoneNumber)}</Td>
                    <Td width="10%" pl={0} style={{textAlign: "center"}}>{user.isSupervisor &&
                        <Icon as={FaCircleCheck} color="primary.500"/>}</Td>
                    <Td width="10%" pl={0} style={{textAlign: "center"}}>{user.lastClientRequestAt &&
                        <Icon as={FaCircleCheck} color="primary.500"/>}</Td>
                    <Td width="5%">
                      <Menu>
                        <MenuButton onClick={(e) => e.stopPropagation() } as={Button} variant={'ghost'} _hover={{bg: 'gray.50'}} _active={{bg: 'transperant'}} _focus={{bg: 'transperant'}}>
                          <Icon as={BsThreeDotsVertical} color="grey" />
                        </MenuButton>
                        <MenuList
                            fontSize="lg"
                            fontWeight={'bold'}
                            py={0}
                            borderRadius="md">
                          <MenuItem
                              borderRadius='md'
                              _hover={{ bg: 'gray.50'}}
                              _focus={{ bg: 'gray.50'}}
                              _active={{ bg: 'gray.50'}}
                              p={4}
                              onClick={(e) => {
                                e.stopPropagation();
                                onRowClick(user.id, modalAction.UPDATE)}
                              }> <Icon mr={4} as={BsPencilFill} color="secondary.darkGray" />
                            Edit
                          </MenuItem>
                          <MenuItem
                              borderRadius='md'
                              color={'red'}
                              _hover={{ bg: 'gray.50' }}
                              _focus={{ bg: 'gray.50' }}
                              _active={{ bg: 'gray.50' }}
                              p={4}
                              onClick={(e) => {
                                e.stopPropagation();
                                onRowClick(user.id, modalAction.DELETE)}
                              }>
                            <Icon mr={4} as={BsTrashFill} color="secondary.red" />
                            Delete
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Flex>
        </Flex>
      </Box>
      <UserModal
        isOpen={isEditModalOpen}
        userAction={userAction}
        users={allUsers}
        user={userToBeEdited ? users[userToBeEdited] : null}
        onClose={(deleted = false, edited = false) => {
          setIsEditModalOpen(false)
          setUserToBeEdited(null)
          setUserAction(null)

          if (deleted || edited) {
            mutate(USERS_INDEX_URL);
          }
          }}
      />
    </Flex>
  );
};

export default UserDashboard;
