import { convertShiftFromServer } from "./shift";

export type CalendarEventLabel = {
  id: number;
  name: string;
  color: string;
}

export type CalendarEvent = {
  scheduledStartDatetime: Date;
  duration: number;
  assignmentId: number;
  supervisorId: number | null;
  locationId: number;
  recurrenceId: number | null;
  detached: boolean;
  assignedUserId: number | null;
  status: string;
  scheduleInfo?: {
    daysOfWeek: number[] | null;
    replacementReason: string | null;
    previousAssignedUserId: number | null;
    notes: string | null;
    disableAttendance: boolean;
    recurrenceStartDatetime: Date | null;
    recurrenceEndDatetime: Date | null;
    isImmutable: boolean;
    labels: number[];
  }
}

export const convertAggregateItemFromServer = (itemFromServer: any, locations: Array<any>, users: Array<any>): any => {
  const assignedUser = users[itemFromServer.employeeId]
  const location = locations[itemFromServer.locationId]

  return {
    ...itemFromServer,
    id: parseInt(`${(assignedUser?.id || 0)}${(location?.id || 0)}`),
    assignedUser: assignedUser ? { id: assignedUser.id, name: assignedUser.name, employmentType: assignedUser.employmentType } : null,
    location: location ? { id: location.id, name: location.name } : null,
  };
}


export const convertCalendarEventFromServer = (itemFromServer: any, locations: Array<any>, users: Array<any>, timezoneContext: any): any => {
  const assignedUser = users[itemFromServer.assignedUserId]
  const location = locations[itemFromServer.locationId]

  // https://stackoverflow.com/questions/48172772/time-zone-issue-involving-date-fns-format
  const dt = timezoneContext.parse(itemFromServer.scheduledStartDatetime);
  const dtDateOnly = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000);

  return {
    ...itemFromServer,
    id: parseInt(`${(assignedUser?.id || 0)}${(location?.id || 0)}${dt.getTime()}`),
    assignedUser: assignedUser ? { id: assignedUser.id, name: assignedUser.name, employmentType: assignedUser.employmentType } : null,
    location: location ? { id: location.id, name: location.name } : null,
    scheduledStartDatetime: dt,
    scheduledStartDate: dtDateOnly,
    shifts: itemFromServer.shifts.map((shift) => convertShiftFromServer(shift, timezoneContext)),
  };
}

