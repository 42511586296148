import { useAuth } from '@/contexts/auth_context';
import { CalendarEventLabel } from '@/types/calendarEvent';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  HStack,
  Box,
  useRadio,
  UseRadioProps,
  useToast
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { mutate } from 'swr';

interface ColorRadioProps extends UseRadioProps {
  color: string;
}

const PRESET_COLORS = [
  '#D50000', // bright red
  '#E67C73', // coral
  '#F4511E', // deep orange
  '#F6BF26', // golden yellow
  '#33B679', // green
  '#0B8043', // dark green
  '#039BE5', // bright blue
  '#616161', // gray
];

const ColorRadio = (props: ColorRadioProps) => {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  return (
    <Box as="label">
      <input {...getInputProps()} />
      <Box
        {...getCheckboxProps()}
        cursor="pointer"
        borderWidth="2px"
        borderRadius="full"
        boxSize="32px"
        bg={props.color}
        _checked={{
          borderColor: 'white',
          outline: '2px solid',
          outlineColor: props.color,
        }}
        _focus={{
          boxShadow: 'outline',
        }}
      />
    </Box>
  );
};

interface CalendarEventLabelModalProps {
  isOpen: boolean;
  onClose: () => void;
  label: CalendarEventLabel | null;
}

const CalendarEventLabelModal = ({
  isOpen,
  onClose,
  label,
}: CalendarEventLabelModalProps) => {
  const [ updatedLabel, setUpdatedLabel ] = useState<CalendarEventLabel | null>(null);
  const isEditing = label !== null;
  const authContext = useAuth();
  const toast = useToast();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const makeRequest = async (url: string, method: string, body: any) => {
    try {
      const response = await authContext.authenticatedFetch(url, {
        method,
        body: JSON.stringify(body)
      });
      if (response.status !== 200) {
        throw new Error('Sorry, something went wrong');
      }
      const data = await response.json();
      // Update the SWR cache for the index endpoint with the new data
      mutate(`${import.meta.env.VITE_API_SERVER}/calendar_event_labels`, data);
      onClose();
    } catch (error) {
      toast({
        title: 'Error',
        position: "bottom",
        variant: "left-accent",
        description: error.message,
        status: 'error',
      });
    }
  }

  useEffect(() => {
    setUpdatedLabel(label);
  }, [label]);

  const onDelete = async() => {
    setShowDeleteConfirmation(true);
  };

  const handleConfirmDelete = async () => {
    await makeRequest(`${import.meta.env.VITE_API_SERVER}/calendar_event_labels/${updatedLabel?.id}`, 'DELETE', {});
    setShowDeleteConfirmation(false);
  };

  const onUpdate = async () => {
    if (!updatedLabel?.name || !updatedLabel?.color) {
      toast({
        title: 'Error',
        position: "bottom",
        variant: "left-accent",
        description: 'Name and color are required',
        status: 'error',
      });
      return;
    }
    await makeRequest(`${import.meta.env.VITE_API_SERVER}/calendar_event_labels/${updatedLabel?.id}`, 'PUT', {
      name: updatedLabel?.name,
      color: updatedLabel?.color
    });
  };

  const onCreate = async () => {
    if (!updatedLabel?.name || !updatedLabel?.color) {
      toast({
        title: 'Error',
        position: "bottom",
        variant: "left-accent",
        description: 'Name and color are required',
        status: 'error',
      });
      return;
    }
    await makeRequest(`${import.meta.env.VITE_API_SERVER}/calendar_event_labels`, 'POST', {
      name: updatedLabel?.name,
      color: updatedLabel?.color
    });
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={() => onClose()} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{isEditing ? 'Edit Label' : 'Create New Label'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4}>
              <FormControl>
                <FormLabel>Name</FormLabel>
                <Input
                  value={updatedLabel?.name || ''}
                  onChange={(e) => setUpdatedLabel({ ...updatedLabel, name: e.target.value })}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Color</FormLabel>
                <HStack>
                  {PRESET_COLORS.map((presetColor) => (
                    <ColorRadio
                      key={presetColor}
                      color={presetColor}
                      isChecked={updatedLabel?.color === presetColor}
                      onChange={() => setUpdatedLabel({ ...updatedLabel, color: presetColor })}
                    />
                  ))}
                </HStack>
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter gap={2} mt={6}>
            {isEditing ? (
              <>
                <Button
                  variant='solid'
                  colorScheme='gray'
                  textColor='red.500'
                  onClick={onDelete}
                  flex={1}
                >
                  Delete
                </Button>
                <Button onClick={onUpdate} flex={1}>Update</Button>
              </>
            ) : (
              <Button onClick={onCreate} flex={1}>Create</Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={showDeleteConfirmation} onClose={() => setShowDeleteConfirmation(false)} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Label</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>Are you sure you want to delete this label? This will remove it from all events.</Box>
          </ModalBody>
          <ModalFooter gap={2}>
            <Button
              colorScheme='red'
              onClick={handleConfirmDelete}
              flex={1}
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CalendarEventLabelModal;